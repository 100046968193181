<div class="limiter">
  <div class="container-login100">
    <div class="wrap-login100">
      <form class="login100-form">
        <span class="error-header p-b-45">
          404
        </span>
        <span class="error-subheader p-b-5">
          Looks Like You're Lost
        </span>
        <span class="error-subheader2 p-b-5">
          The Page You Are Looking For Is Not Available!
        </span>
        <div class="container-login100-form-btn p-t-30">
          <button type="button" mat-flat-button class="btn-primary login100-form-btn" routerLink="/authentication/signin">
            Go To Home Page
          </button>
        </div>
      </form>
      <div class="login100-more" style="background-image: url('assets/images/404.png');">
      </div>
    </div>
  </div>
</div>
